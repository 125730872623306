import {
  ApplicationInsights,
  IConfig,
} from "@microsoft/applicationinsights-web";
import {
  ReactPlugin,
  withAITracking,
} from "@microsoft/applicationinsights-react-js";

const reactPlugin = new ReactPlugin();

const ai = new ApplicationInsights({
  config: {
    connectionString:
      "InstrumentationKey=c8d681da-047f-4d35-bcff-68d88d2d4937;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/;ApplicationId=288b2267-037a-4aa0-a12d-8eef072e83b5",
    enableAutoRouteTracking: true,
    extensions: [reactPlugin],
  } as IConfig,
});

ai.loadAppInsights();

/**
 * Higher-Order Component to wrap a React component with AI Tracking
 * @param Component - React component to wrap
 * @returns Wrapped component with AI Tracking
 */
const withTelemetry = <P extends {}>(
  Component: React.ComponentType<P>,
): React.ComponentType<P> => withAITracking(reactPlugin, Component);

// Export the initialized Application Insights and the tracking HOC
export default withTelemetry;
export const appInsights = ai.appInsights;
